import {
  Box,
  Button,
  CloseButton,
  DrawerFooter,
  FormLabel,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { Drawer, Input } from "../../../../../../../components/common";
import SelectRadio from "../../../../../../../components/common/SelectRadio";
import {
  createProcessSale,
  getVehicleDetailByInventoryId,
} from "../../../../../../../utils/api/lead-deatail.api";
import { useUserInfo } from "../../../../../../../utils/hooks/useUserInfo";
import Loader from "../../../../../../common/Spinner";
import { loadDealershipUsers } from "../../../../../../Leads/utils";
import {
  CreateProcessSalesForm,
  CreateProcessSalesFormSchema,
  defaultValues,
  deliveryStatusOptions,
  loadVehicles,
  paymentOptions,
} from "./utils";
import { useHistoryDispatch } from "../../../../../../../utils/context/HistoryContext";
type data = {
  isOpen: boolean;
  onClose: () => void;
  contact_id: string | number;
  indexSetter: (val: any, val2: any) => void;
};
const Pending = ({ isOpen, onClose, contact_id, indexSetter }: data) => {
  const dealership = useUserInfo("dealership");
  const toast = useToast();
  const dispatch = useHistoryDispatch();
  const mutation = useMutation(createProcessSale);
  const { handleSubmit, control, watch, setValue } =
    useForm<CreateProcessSalesForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(CreateProcessSalesFormSchema) as any,
    });
  const selectedVehicle = watch("vehicle_attributes.inventory_id");
  const { data: vehicleDetail } = useQuery(
    ["vehicleDetail", dealership?.id, selectedVehicle?.value],
    getVehicleDetailByInventoryId,
    {
      enabled: !!selectedVehicle?.value,
    }
  );
  useEffect(() => {
    if (selectedVehicle) {
      // If a vehicle is selected, disable and clear the make, year, model, and vin fields
      setValue("vehicle_attributes.make", "");
      setValue("vehicle_attributes.year", "");
      setValue("vehicle_attributes.model", "");
      setValue("vehicle_attributes.vin", "");
      setValue("vehicle_attributes.stock", "");
    }
  }, [selectedVehicle, setValue]);
  const handleFormSubmit = (values: CreateProcessSalesForm) => {
    mutation.mutate(
      {
        id: dealership?.id,
        contact_id: contact_id,
        data: {
          process_sale: {
            term: values?.term,
            status: values?.delivery_status?.value,
            notes: values?.notes,
            year: values?.vehicle_attributes?.year,
            make: values?.vehicle_attributes?.make,
            model: values?.vehicle_attributes?.model,
            vin: values?.vehicle_attributes?.vin,
            stock_number: values?.vehicle_attributes?.stock,
            inventory_id: values?.vehicle_attributes?.inventory_id?.value,
            sales_person_ids: values?.sales_person_id?.map((val) => val?.value),
            payment_type: values?.payment_type?.value,
            sale_type: "sales",
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          onClose();
          dispatch({
            type: "SET_SHOULD_REFETCH_DETAIL",
            payload: { shouldRefetchDetail: true },
          });
          toast({
            description: "Process Sale added successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          indexSetter(0, 7);
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Add Process Sale">
      <Box padding="1.25rem">
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
        >
          Who Sold the Vehicle?
        </FormLabel>
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AsyncSelect
                loadOptions={(searchStr) =>
                  loadDealershipUsers(searchStr, "", dealership?.id)
                }
                defaultOptions
                value={value}
                onChange={onChange}
                isMulti
                placeholder="Who Sold the Vehicle?"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100%",
                    width: "100%",
                  }),
                  container: (provided) => ({
                    ...provided,
                    maxWidth: "100%",
                    width: "100%",
                  }),
                }}
              />
            );
          }}
          name="sales_person_id"
          control={control}
          rules={{
            required: true,
          }}
        />
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
          paddingTop="1rem"
        >
          What stock number did you sell? Let us select a vehicle from the
          inventory{" "}
        </FormLabel>

        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AsyncSelect
                loadOptions={(searchStr) =>
                  loadVehicles(searchStr, dealership?.id)
                }
                isClearable
                defaultOptions
                value={value}
                onChange={onChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    maxWidth: "100%",
                    width: "100%",
                  }),
                  container: (provided) => ({
                    ...provided,
                    maxWidth: "100%",
                    width: "100%",
                  }),
                }}
              />
            );
          }}
          name="vehicle_attributes.inventory_id"
          control={control}
          rules={{
            required: true,
          }}
        />
        {selectedVehicle?.label && (
          <Box
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            padding="0.5rem 1.3rem"
            cursor="pointer"
            minH="6.88rem"
            marginTop="1rem"
            // onClick={handleClick}
            // key={id}
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              marginBottom="0.5rem"
            >
              <Text textStyle="h5" fontWeight="600">
                Vehicle Details:
              </Text>
              <CloseButton
                onClick={() =>
                  setValue("vehicle_attributes.inventory_id", null)
                }
              />
            </HStack>
            <HStack w="100%" alignItems="flex-start" gap="0.5rem">
              <VStack gap="0.5rem" alignItems="flex-start">
                <Text textStyle="h6" fontWeight="400">
                  Stock No:
                </Text>
                <Text textStyle="h6" fontWeight="400">
                  Year:
                </Text>
                <Text textStyle="h6" fontWeight="400">
                  Make:
                </Text>
                <Text textStyle="h6" fontWeight="400">
                  Model:
                </Text>
                <Text textStyle="h6" fontWeight="400">
                  VIN:
                </Text>
              </VStack>
              <VStack gap="0.5rem" alignItems="flex-start">
                <Text textStyle="h6" fontWeight="500">
                  {vehicleDetail?.data?.stock_number}
                </Text>
                <Text textStyle="h6" fontWeight="500">
                  {vehicleDetail?.data?.year}
                </Text>
                <Text textStyle="h6" fontWeight="500">
                  {vehicleDetail?.data?.make}
                </Text>
                <Text textStyle="h6" fontWeight="500">
                  {vehicleDetail?.data?.model}
                </Text>
                <Text textStyle="h6" fontWeight="500">
                  {vehicleDetail?.data?.vin}
                </Text>
              </VStack>
            </HStack>
          </Box>
        )}
        <HStack w="100%" gap="1.5rem" paddingTop="1rem">
          {!selectedVehicle?.value && (
            <>
              <Box
                as="span"
                width="100%"
                height="1px"
                background="var(--grey-300)"
              ></Box>
              <Box
                as="span"
                fontSize="1.125rem"
                fontWeight="600"
                color="var(--secondary-600)"
                borderRadius="6.25rem"
                background="var(--secondary-50)"
                padding="0.31rem 1.19rem"
              >
                Or
              </Box>
              <Box
                as="span"
                width="100%"
                height="1px"
                background="var(--grey-300)"
              ></Box>
            </>
          )}
        </HStack>

        <VStack alignItems="flex-start" w="100%" gap="1rem">
          {!selectedVehicle?.value && (
            <>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Enter Year"
                      label="Year"
                      onChange={onChange}
                      type="number"
                      value={value}
                      error={error?.message}
                      isDisabled={selectedVehicle?.value ? true : false}
                    />
                  );
                }}
                name="vehicle_attributes.year"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Enter Make"
                      label="Make"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                      isDisabled={selectedVehicle?.value ? true : false}
                    />
                  );
                }}
                name="vehicle_attributes.make"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Enter Model"
                      label="Model"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                      isDisabled={selectedVehicle?.value ? true : false}
                    />
                  );
                }}
                name="vehicle_attributes.model"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Enter VIN"
                      label="VIN"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                      isDisabled={selectedVehicle?.value ? true : false}
                    />
                  );
                }}
                name="vehicle_attributes.vin"
                control={control}
                rules={{
                  required: true,
                }}
              />
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      placeholder="Stock No"
                      label="Stock No"
                      onChange={onChange}
                      value={value}
                      error={error?.message}
                      isDisabled={selectedVehicle?.value ? true : false}
                    />
                  );
                }}
                name="vehicle_attributes.stock"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </>
          )}
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <SelectRadio
                  isStepper={false}
                  options={paymentOptions}
                  label="Payment Details"
                  onChange={onChange}
                  defaultValue={value}
                  // error={error?.message}
                />
              );
            }}
            name="payment_type"
            control={control}
            rules={{
              required: true,
            }}
          />
          {/* </VStack> */}
          {/* <VStack w="100%" alignItems="flex-start" gap="1rem">
        <HStack w="100%" gap="0.75rem"> */}
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Term"
                  type="number"
                  placeholder="Term"
                  onChange={onChange}
                  value={value}
                  error={error?.message}
                />
              );
            }}
            name="term"
            control={control}
            rules={{
              required: true,
            }}
          />
          {/* </HStack>
      </VStack> */}
          <VStack w="130%" alignItems="flex-start" gap="1rem" mt="1rem">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SelectRadio
                    isStepper={false}
                    options={deliveryStatusOptions}
                    label="Delivery Status"
                    onChange={onChange}
                    defaultValue={value}
                    // error={error?.message}
                  />
                );
              }}
              name="delivery_status"
              control={control}
              rules={{
                required: true,
              }}
            />
          </VStack>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  isTextarea={true}
                  label="Deal Notes"
                  onChange={onChange}
                  value={value}
                  error={error?.message}
                />
              );
            }}
            name="notes"
            control={control}
            rules={{
              required: true,
            }}
          />
        </VStack>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit((values) => handleFormSubmit(values))();
            }}
          >
            Submit
          </Button>
        </DrawerFooter>
      </Box>
      {mutation?.isLoading && <Loader />}
    </Drawer>
  );
};

export default Pending;
