import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Controller, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Input, PhoneInput, Select } from "../../../components/common";
import Loader from "../../../components/common/Spinner";
import { updateContact } from "../../../utils/api/guest-mode";

const PersonalInfo = (props: any) => {
  const { control, handleSubmit, setValue, setActiveStep } = props;
  const { id, dId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const email = useWatch({ control, name: "email" });
  const phone_number = useWatch({ control, name: "phone_number" });

  const isEmailOrPhoneEmpty = !email && !phone_number;

  const prefixes = [
    "Ms.",
    "Miss",
    "Mrs.",
    "Mr.",
    "Master",
    "Rev.",
    "Fr.",
    "Dr.",
    "Atty.",
    "Prof.",
    "Hon.",
    "Pres.",
    "Gov.",
    "Coach",
    "Ofc.",
  ];

  const mutation = useMutation(updateContact);

  const onSubmit = (data: any) => {
    if (id === "[contact_id]") {
      if (!data.phone_number) {
        data.phone_number = "";
      }
      if (!data.home_number) {
        data.home_number = "";
      }
      if (!data.work_number) {
        data.work_number = "";
      }
      mutation.mutate(
        { contact_uuid: id, dId, payload: { contact: data } },
        {
          onSuccess: (data: any) => {
            if (id === "[contact_id]") {
              navigate(
                `/guest_mode/dealership/${dId}/contact/${data?.data?.uuid}/apply_for_credit`
              );
            } else {
              toast({
                description: "My profile updated successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            }
          },
          onError: (error: any) => {
            toast({
              description: `Error updating My profile: ${
                error?.response?.data?.errors?.toString() ??
                "Something went wrong"
              }`,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    } else {
      setActiveStep(1);
    }
  };
  return (
    <VStack position="relative" w={{ base: "360px", md: "100%" }} margin={"auto"}>
      {mutation?.isLoading && <Loader />}
      <VStack
        w="100%"
        alignItems="flex-start"
        padding={{base: "0rem", md: "1rem 0 4rem"}}
        gap="0.5rem"
        h={{ md: "calc(100vh - 190px)", base: "calc(100vh - 250px)" }}
        overflow="auto"
      >
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <Text fontWeight="700" textStyle="h4">
              User Information
            </Text>
            <HStack
              w={{ md: "100%", base: "100%" }}
              alignItems="flex-start"
              flexWrap={{ md: "nowrap", base: "wrap" }}
            >
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="First Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="middle_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Middle Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Last Name"
                    maxLength={100}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
            <HStack w="100%">
              <Controller
                name="prefix"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    label="Prefix"
                    placeholder="Prefix"
                    onChange={(value: any) => setValue("prefix", value?.value)}
                    options={prefixes?.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    variant="default"
                    w="100%"
                    error={error?.message || ""}
                  />
                )}
              />

              <Controller
                name="company_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder=""
                    label="Company Name"
                    maxLength={200}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
          </VStack>
        </VStack>
        <VStack alignItems="flex-start" mb="10px" w="100%">
          {" "}
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <Text fontWeight="700" textStyle="h4">
              Phone
            </Text>
            <HStack w="100%" flexWrap={{ md: "nowrap", base: "wrap" }}>
              <Controller
                name="phone_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Mobile"
                    placeholder="Enter phone number"
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="home_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Home (Optional)"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
              <Controller
                name="work_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <PhoneInput
                    {...field}
                    label="Work  (Optional)"
                    placeholder="Enter phone number"
                    isRequired={false}
                    error={error?.message || ""}
                  />
                )}
              />
            </HStack>
          </VStack>
        </VStack>

        <VStack alignItems="flex-start" mb="10px" w="100%">
          <Text fontWeight="700" textStyle="h5">
            Email
          </Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            padding="1rem"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            background="#efeded9c"
            gap="0.5rem"
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  type="text"
                  placeholder=""
                  label="Email Address"
                  maxLength={320}
                  error={error?.message || ""}
                  w="482px"
                />
              )}
            />
          </VStack>
          {isEmailOrPhoneEmpty && (
            <Text color="red.500">
              Please ensure you provide either an email address or a phone
              number
            </Text>
          )}
        </VStack>
      </VStack>
      <HStack
        justifyContent="flex-end"
        padding="0px"
        w="100%"
        bg={{ md: "transparent", base: "#fff" }}
        position={{base: "static", md: "absolute"}}
        right="0"
        bottom={{ md: "5px", base: "30px" }}
      >
        <Button
          w="200px"
          marginTop="auto"
          onClick={handleSubmit(onSubmit)}
          zIndex={999}
          isDisabled={isEmailOrPhoneEmpty}
        >
          {id === "[contact_id]" ? "Save Contact" : "Next"}
        </Button>
      </HStack>
    </VStack>
  );
};

export default PersonalInfo;
