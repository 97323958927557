import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useToast,
  IconButton,
  Stack,
  Checkbox,
} from "@chakra-ui/react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Input, Select } from "../../../../components/common";
import SwitchToggle from "../../../../components/common/Switch";
import { RouteIcon } from "../../../../components/icons/svg";
import { isValidEmail } from "../../../../utils";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import { Link } from "react-router-dom";
import { getMessengerCheckout } from "../../../../utils/api/social-talk.api";
import {
  getDealershipCrm,
  getDealershipDms,
  getDealershipVinSolution,
  getDealershipVinSolutionUsers,
  getInventoryProvider,
  getsettingsApi,
  sendInventorymsg,
  updatesettingApi,
  getFeedUrlApi,
} from "../../../../utils/api/dealership-setting.api";
import { FeedUrls } from "./FeedUrls/FeedUrls";
import Loader from "../../../common/Spinner";
import MessengerLogo from "../../../icons/Messenger";
import FacebookIntegration from "./FacebookIntegration/FacebookIntegration";
import TiktokIntegration from "./TiktokIntegration/TiktokIntegration";
import VinSolution from "./VinSolution";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ApiLogs } from "./ApiLogs";
import moment from "moment";
import _ from "lodash"; // To perform deep comparison of objects
import { hoursOption, formoptions } from "../../SaraAI/utils";
import { CustomRadio } from "../../../../components/common";
import TimeIcon from "../../../icons/Time";
import { daysStructure } from "../../../../config/constants/days";

const Integration = () => {
  const myDivRef = useRef<any>(null);
  const toast = useToast();
  const { id } = useParams();
  const userid = useUserInfo("id");
  const mutation = useMutation(updatesettingApi);
  const msgMutation = useMutation(sendInventorymsg);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["settingsdata", id, "integration_settings"],
    queryFn: getsettingsApi,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [settingsHash, setSettingsHash] = useState(data?.data?.settings_hash);
  const [form, setForm] = useState<any>("sales_instructions");
  const { data: ftp } = useQuery({
    queryKey: ["settingsdata", id, "ftp_credentials"],
    queryFn: getsettingsApi,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: crm } = useQuery({
    queryKey: ["crm"],
    queryFn: getDealershipCrm,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: dms } = useQuery({
    queryKey: ["dms"],
    queryFn: getDealershipDms,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: vs } = useQuery({
    queryKey: ["vs", id],
    queryFn: getDealershipVinSolution,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: vsUsers } = useQuery({
    queryKey: [
      "vsUsers",
      id,
      vs?.data?.data?.find(
        (item: any) =>
          item?.dealer_id === settingsHash?.crm_integrations?.dealer_id
      )?.id,
    ],
    queryFn: getDealershipVinSolutionUsers,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!settingsHash?.crm_integrations?.dealer_id,
  });
  const { data: inventory } = useQuery({
    queryFn: getInventoryProvider,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleSubmit = () => {
    let modifiedSettingsHash = { ...settingsHash };
    // Check the if condition and update the `created_at` if necessary
    if (
      data?.data?.settings_hash?.crm_integrations &&
      !data?.data?.settings_hash?.crm_integrations?.created_at &&
      settingsHash?.crm_integrations?.crm_provider !== ""
    ) {
      modifiedSettingsHash = {
        ...modifiedSettingsHash,
        crm_integrations: {
          ...settingsHash.crm_integrations,
          created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
          updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      };
    }
    const crmIntegrationsChanged = !_.isEqual(
      data?.data?.settings_hash?.crm_integrations,
      settingsHash?.crm_integrations
    );
    if (
      crmIntegrationsChanged &&
      data?.data?.settings_hash?.crm_integrations?.created_at
    ) {
      modifiedSettingsHash = {
        ...modifiedSettingsHash,
        crm_integrations: {
          ...modifiedSettingsHash.crm_integrations,
          updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      };
    }
    if (
      data?.data?.settings_hash?.dms_integrations &&
      !data?.data?.settings_hash?.dms_integrations?.created_at &&
      settingsHash?.dms_integrations?.dms_provider !== ""
    ) {
      modifiedSettingsHash = {
        ...modifiedSettingsHash,
        dms_integrations: {
          ...settingsHash.dms_integrations,
          created_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
          updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      };
    }
    const dmsIntegrationsChanged = !_.isEqual(
      data?.data?.settings_hash?.dms_integrations,
      settingsHash?.dms_integrations
    );
    if (
      dmsIntegrationsChanged &&
      data?.data?.settings_hash?.dms_integrations?.created_at
    ) {
      modifiedSettingsHash = {
        ...modifiedSettingsHash,
        dms_integrations: {
          ...modifiedSettingsHash.dms_integrations,
          updated_at: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      };
    }

    // Modify the rest of the settings hash for lead delivery email address
    modifiedSettingsHash = {
      ...modifiedSettingsHash,
      lead_delivery_email_address: {
        ...settingsHash.lead_delivery_email_address,
        appointment_recipient:
          settingsHash?.lead_delivery_email_address?.appointment_recipient?.map(
            (emailObj: any) => emailObj.value
          ),
        lead_delivery_email:
          settingsHash?.lead_delivery_email_address?.lead_delivery_email?.map(
            (emailObj: any) => emailObj.value
          ),
      },
    };
    mutation.mutate(
      {
        data: {
          key: "integration_settings",
          settings_hash: modifiedSettingsHash,
        },
        id: id,
        sub_menu: "Dealership settings/Dealer Profile/Integration",
      },
      {
        onSuccess: () => {
          refetch();
          toast({
            description: "Settings updated successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const sendMessage = () => {
    msgMutation.mutate(
      {
        payload: {
          dealership: {
            inventory_feed_provider_id: Number(
              inventorymsg?.inventory_feed_provider_id
            ),
            cc: inventorymsg.cc,
          },
        },
        id: id,
      },
      {
        onSuccess: () => {
          toast({
            description: "Email sent successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleTabsChange = (index: any) => {
    setSelectedTabIndex(index);
  };
  const [selectedCRMTabIndex, setSelectedCRMTabIndex] = useState(0);
  const handleCRMTabsChange = (index: any) => {
    setSelectedCRMTabIndex(index);
  };
  const [selectedDMSTabIndex, setSelectedDMSTabIndex] = useState(0);
  const handleDMSTabsChange = (index: any) => {
    setSelectedDMSTabIndex(index);
  };
  const [inventorymsg, setInventorymsg] = useState<any>({
    inventory_feed_provider_id: "",
    cc: [],
  });

  useEffect(() => {
    setSettingsHash(data?.data?.settings_hash);
    setMetadata(data?.data?.settings_hash?.crm_integrations?.metadata);
    setInventorymsg((prevData: any) => ({
      ...prevData,
      inventory_feed_provider_id:
        data?.data?.settings_hash?.inventory_provider_params?.id,
    }));
  }, [data?.data?.settings_hash, inventory?.data?.inventory_feed_providers]);
  const handleInventoryProviderChange = (selectedOptions: any) => {
    setInventorymsg((prevData: any) => ({
      ...prevData,
      inventory_feed_provider_id: selectedOptions.value,
    }));
  };
  const handleInputChange = (section: any, property: string, value: any) => {
    setSettingsHash((prevState: any) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [property]: value,
      },
    }));
  };
  const handleHoursChange = (index: number, key: string, value: any) => {
    setSettingsHash((prevState: any) => ({
      ...prevState,
      [form]: {
        ...prevState?.[form],
        hours:
          prevState?.[form]?.hours?.length > 0
            ? prevState?.[form]?.hours.map((item: any, idx: any) =>
                idx === index ? { ...item, [key]: value } : item
              )
            : Array.from({ length: 7 }, (_, i) => ({
                label: [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ][i],
                checked: false,
                startTime: "",
                endTime: "",
              })),
      },
    }));
  };
  const handleCcChange = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();

      const newCc: any = event.currentTarget.value.trim();

      if (isValidEmail(newCc) && !inventorymsg?.cc?.includes(newCc)) {
        setInventorymsg((prevData: any) => ({
          ...prevData,
          cc: [...prevData.cc, newCc],
        }));

        event.currentTarget.value = "";
      }
    }
  };
  const renderPills = () => {
    return inventorymsg?.cc?.map((option: any) => (
      <Flex
        key={option}
        alignItems="center"
        padding="0.125rem 0.3125rem"
        gap="0.5rem"
        borderRadius="1.5rem"
        background="var(--grey-200)"
        mt={2}
      >
        <Text
          color="var(--grey-900)"
          fontSize="0.875rem"
          font-weight="400"
          lineHeight="1.25rem"
          mr={1}
        >
          {option}
        </Text>
        <Box
          as="span"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => handleRemoveOption(option)}
        >
          <CancelIcon
            style={{
              color: "var(--bluegray-300)",
              width: "1rem",
              height: "1rem",
            }}
          />
        </Box>
      </Flex>
    ));
  };
  const handleRemoveOption = (optionToRemove: any) => {
    setInventorymsg((prevData: any) => ({
      ...prevData,
      cc: prevData.cc.filter((option: any) => option !== optionToRemove),
    }));
  };
  const [options, setOptions] = useState<SelectOptionProps[]>([
    { label: "Single file", value: "single_file" },
    {
      label: "Multiple Dealerships in one Directory",
      value: "multiple_dealerships_directory",
    },
    {
      label: "Multiple Dealerships in one File",
      value: "multiple_dealerships_file",
    },
  ]);
  useEffect(() => {
    // Parse the search query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const isFacebook = queryParams.has("facebook");
    const isTikTok = queryParams.has("tiktok");

    if (isFacebook || isTikTok) {
      if (myDivRef?.current) {
        myDivRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      isFacebook ? setSelectedTabIndex(0) : setSelectedTabIndex(1);
    }
  }, []);
  const [metadata, setMetadata] = useState<any>([]);
  const addNewRow = () => {
    if (metadata?.length > 0) {
      setMetadata([...metadata, { data_key: "", data_value: "" }]);
    } else {
      setMetadata([{ data_key: "FranchiseName||DsDealerId", data_value: "" }]);
    }
  };
  const handleMetadataChange = (index: any, field: any, value: any) => {
    const newMetadata = [...metadata];
    newMetadata[index][field] = value;
    setMetadata(newMetadata);
    handleInputChange("crm_integrations", "metadata", newMetadata);
  };
  const removeRow = (index: any) => {
    const newMetadata = metadata.filter((_: any, i: any) => i !== index);
    setMetadata(newMetadata);
    handleInputChange("crm_integrations", "metadata", newMetadata);
  };
  const formHandler=(val:any)=>{
    setForm(val?.value)
  }
  const formRef = useRef(form);

useEffect(() => {
  formRef.current = form;
}, [form]);
  return (
    <>
      <VStack background="white" gap="1.5rem">
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          gap="1.5rem"
        >
          <Text fontWeight="700" textStyle="h4">
            Webchat Integration
          </Text>
          <VStack w="100%" gap="1.25rem" alignItems="flex-start">
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="Website Chat"
                  defaultValue={
                    "<script src=https://auto-expo.dealer.simpsocial.com/chat-popup.js></script>"
                  }
                  value={settingsHash?.general_integration?.website_chat}
                  onChange={(e) => {
                    handleInputChange(
                      "general_integration",
                      "website_chat",
                      e.target.value
                    );
                  }}
                  isReadOnly={true}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  Paste the code before the closing of the head tag
                </Text>
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="VDP Chat"
                  defaultValue={
                    "<script src=https://auto-expo.dealer.simpsocial.com/chat-popup.js></script>"
                  }
                  value={settingsHash?.general_integration?.vdp_chat_1}
                  onChange={(e) => {
                    handleInputChange(
                      "general_integration",
                      "vdp_chat_1",
                      e.target.value
                    );
                  }}
                  isReadOnly={true}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  1) Paste the code before the closing of the head tag
                </Text>
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  // label="VDP Chat"
                  defaultValue={
                    "https://auto-expo.dealer.simpsocial.com/webhook/voucher"
                  }
                  value={settingsHash?.general_integration?.vdp_chat_2}
                  onChange={(e) => {
                    handleInputChange(
                      "general_integration",
                      "vdp_chat_2",
                      e.target.value
                    );
                  }}
                  isReadOnly={true}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  2) Embed the provided code where you want the button,
                  replacing variables with accurate values.
                </Text>
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="SRP Chat"
                  defaultValue={
                    "<script src=https://auto-expo.dealer.simpsocial.com/chat-popup.js></script>"
                  }
                  value={settingsHash?.general_integration?.srp_chat_1}
                  onChange={(e) => {
                    handleInputChange(
                      "general_integration",
                      "srp_chat_1",
                      e.target.value
                    );
                  }}
                  isReadOnly={true}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  1) Paste the code before the closing of the head tag
                </Text>
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  // label="SRP Chat"
                  defaultValue={
                    "https://auto-expo.dealer.simpsocial.com/webhook/voucher"
                  }
                  value={settingsHash?.general_integration?.srp_chat_2}
                  onChange={(e) => {
                    handleInputChange(
                      "general_integration",
                      "srp_chat_2",
                      e.target.value
                    );
                  }}
                  isReadOnly={true}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  2) Embed the provided code where you want the button,
                  replacing variables with accurate values.
                </Text>
              </VStack>
            </HStack>
            <HStack>
              <SwitchToggle
                label=" Sarah.ai Integration"
                defaultChecked={settingsHash?.sarahai?.sarahai_integration}
                onChange={(val) =>
                  handleInputChange("sarahai", "sarahai_integration", val)
                }
              />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="Homepage Instructions"
                  isTextarea={true}
                  value={settingsHash?.sarahai?.homepage_instructions}
                  onChange={(e) => {
                    handleInputChange(
                      "sarahai",
                      "homepage_instructions",
                      e?.target?.value
                    );
                  }}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  Supported variables: [company_name], [address], [website],
                  [phone_number]
                </Text>
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="VDP Instructions"
                  isTextarea={true}
                  value={settingsHash?.sarahai?.vdp_instructions}
                  onChange={(e) => {
                    handleInputChange(
                      "sarahai",
                      "vdp_instructions",
                      e?.target?.value
                    );
                  }}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  Supported variables: [company_name], [address], [website],
                  [phone_number]{" "}
                </Text>
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="SRP Instructions"
                  isTextarea={true}
                  value={settingsHash?.sarahai?.srp_instructions}
                  onChange={(e) => {
                    handleInputChange(
                      "sarahai",
                      "srp_instructions",
                      e?.target?.value
                    );
                  }}
                />
                <Text textStyle="h6" color="var(--grey-800)">
                  Supported variables: [company_name], [address], [website],
                  [phone_number]
                </Text>
              </VStack>
            </HStack>

            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  type="number"
                  maxW="100%"
                  label="Threshold Time"
                  value={settingsHash?.sarahai?.threshold_time}
                  onChange={(e) => {
                    handleInputChange(
                      "sarahai",
                      "threshold_time",
                      e?.target?.value
                    );
                  }}
                  min={0}
                  error={
                    settingsHash?.sarahai?.threshold_time < 2 &&
                    settingsHash?.sarahai?.threshold_time
                      ? "Must be greater or equal to 2"
                      : ""
                  }
                />
              </VStack>
            </HStack>
          </VStack>
        </VStack>

        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          gap="1.5rem"
        >
          <Text fontWeight="700" textStyle="h4">
            CRM Integrations
          </Text>
          <Tabs
            variant="primary"
            w="100%"
            onChange={handleCRMTabsChange}
            defaultIndex={0}
            index={selectedCRMTabIndex}
          >
            <TabList>
              <Tab>Integration</Tab>
              <Tab>API Logs</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {selectedCRMTabIndex === 0 && (
                  <>
                    <VStack w="100%" gap="1.25rem" alignItems="flex-start">
                      <HStack paddingTop={"1.25rem"}>
                        <SwitchToggle
                          label="Active"
                          defaultChecked={
                            settingsHash?.crm_integrations?.status
                          }
                          onChange={(val) =>
                            handleInputChange("crm_integrations", "status", val)
                          }
                        />
                      </HStack>
                      <HStack w="100%" gap="1.25rem" alignItems="flex-start">
                        <Select
                          label="Select CRM Provider"
                          options={
                            crm?.data?.crm?.map((crm: any) => ({
                              label: crm,
                              value: crm,
                            })) ?? []
                          }
                          variant="default"
                          w="50%"
                          value={settingsHash?.crm_integrations?.crm_provider}
                          onChange={(value) => {
                            handleInputChange(
                              "crm_integrations",
                              "crm_provider",
                              value?.value
                            );
                          }}
                        />
                        <Input
                          maxW="50%"
                          label="Subscription ID"
                          defaultValue="Auto Expo"
                          value={
                            settingsHash?.crm_integrations?.subscription_id
                          }
                          onChange={(e) => {
                            handleInputChange(
                              "crm_integrations",
                              "subscription_id",
                              e?.target?.value
                            );
                          }}
                          isDisabled={
                            settingsHash?.crm_integrations?.crm_provider ===
                            "VinSolutions"
                          }
                        />
                      </HStack>
                    </VStack>
                    <VStack w="100%" gap="1.25rem" alignItems="flex-start">
                      {settingsHash?.crm_integrations?.crm_provider ===
                        "eleads" && (
                        <HStack
                          w="100%"
                          gap="1.25rem"
                          alignItems="flex-start"
                          paddingTop={"1.25rem"}
                        >
                          {" "}
                          <SwitchToggle
                            id="1"
                            label="Agent Owned Contacts Only"
                            defaultChecked={
                              settingsHash?.crm_integrations
                                ?.agent_owned_contacts_only
                            }
                            onChange={(check: boolean) => {
                              handleInputChange(
                                "crm_integrations",
                                "agent_owned_contacts_only",
                                check
                              );
                            }}
                          />
                        </HStack>
                      )}{" "}
                      {settingsHash?.crm_integrations?.crm_provider ===
                        "DealerSocket" && (
                        <VStack w="100%" gap="1.25rem" alignItems="flex-start">
                          {" "}
                          <HStack paddingTop={"1.25rem"}>
                            <SwitchToggle
                              id="1"
                              label="Send WorkNote"
                              defaultChecked={
                                settingsHash?.crm_integrations
                                  ?.to_send_work_note
                              }
                              onChange={(check: boolean) => {
                                handleInputChange(
                                  "crm_integrations",
                                  "to_send_work_note",
                                  check
                                );
                              }}
                            />
                            <SwitchToggle
                              id="1"
                              label="Send Appointment"
                              defaultChecked={
                                settingsHash?.crm_integrations
                                  ?.to_send_appointment
                              }
                              onChange={(check: boolean) => {
                                handleInputChange(
                                  "crm_integrations",
                                  "to_send_appointment",
                                  check
                                );
                              }}
                            />
                          </HStack>
                          <HStack>
                            <Text fontWeight="500">Metadata</Text>
                            <IconButton
                              aria-label="Add Row"
                              icon={<AddIcon />}
                              onClick={addNewRow}
                            />{" "}
                          </HStack>
                          {metadata?.map((item: any, index: any) => (
                            <HStack key={index} w="100%">
                              <Input
                                placeholder="Data Key"
                                value={item.data_key}
                                onChange={(e) =>
                                  handleMetadataChange(
                                    index,
                                    "data_key",
                                    e.target.value
                                  )
                                }
                              />
                              <Input
                                placeholder="Data Value"
                                value={item.data_value}
                                onChange={(e) =>
                                  handleMetadataChange(
                                    index,
                                    "data_value",
                                    e.target.value
                                  )
                                }
                              />
                              <IconButton
                                aria-label="Remove Row"
                                icon={<DeleteIcon />}
                                onClick={() => removeRow(index)}
                              />
                            </HStack>
                          ))}
                          {/* Button to Add New Row */}
                        </VStack>
                      )}{" "}
                      {settingsHash?.crm_integrations?.crm_provider ===
                        "DealerVault" && (
                        <HStack
                          w="100%"
                          gap="1.25rem"
                          alignItems="flex-start"
                          paddingTop={"1.25rem"}
                        >
                          {" "}
                          <Input
                            maxW="50%"
                            label="FTP Folder"
                            value={settingsHash?.crm_integrations?.ftp_folder}
                            onChange={(e) => {
                              handleInputChange(
                                "crm_integrations",
                                "ftp_folder",
                                e?.target?.value
                              );
                            }}
                          />{" "}
                          <Input
                            maxW="50%"
                            label="Historical FTP Folder"
                            value={
                              settingsHash?.crm_integrations?.hist_ftp_folder
                            }
                            onChange={(e) => {
                              handleInputChange(
                                "crm_integrations",
                                "hist_ftp_folder",
                                e?.target?.value
                              );
                            }}
                          />
                        </HStack>
                      )}
                      {settingsHash?.crm_integrations?.crm_provider ===
                        "VinSolutions" && (
                        <>
                          <HStack
                            w="100%"
                            gap="1.25rem"
                            alignItems="flex-start"
                            paddingTop={"1.25rem"}
                          >
                            {" "}
                            <Select
                              label="VinSolutions Dealer Id"
                              options={
                                vs?.data?.data?.map((vin: any) => ({
                                  label: vin?.name,
                                  value: vin?.dealer_id,
                                  id: vin?.id,
                                })) ?? []
                              }
                              variant="default"
                              w="50%"
                              value={
                                settingsHash?.crm_integrations?.subscription_id
                              }
                              onChange={(value) => {
                                handleInputChange(
                                  "crm_integrations",
                                  "subscription_id",
                                  value?.value
                                );
                                handleInputChange(
                                  "crm_integrations",
                                  "dealer_id",
                                  value?.value
                                );
                              }}
                            />
                            <Select
                              label="VinSolutions Users"
                              options={
                                vsUsers?.data?.data?.map((vin: any) => ({
                                  label: vin?.FullName + " - " + vin?.IlmAccess,
                                  value: vin?.UserId,
                                })) ?? []
                              }
                              variant="default"
                              w="50%"
                              value={settingsHash?.crm_integrations?.user_id}
                              onChange={(value) => {
                                handleInputChange(
                                  "crm_integrations",
                                  "user_id",
                                  value?.value
                                );
                              }}
                            />
                          </HStack>
                          <Text fontWeight="500">Feature Name</Text>
                          <HStack
                            w="100%"
                            gap="1.25rem"
                            alignItems="flex-start"
                          >
                            {" "}
                            <SwitchToggle
                              id="1"
                              label="To send Contacts DNC request to VinSolutions"
                              defaultChecked={
                                settingsHash?.crm_integrations
                                  ?.to_send_contact_dnc
                              }
                              onChange={(check: boolean) => {
                                handleInputChange(
                                  "crm_integrations",
                                  "to_send_contact_dnc",
                                  check
                                );
                              }}
                            />
                            <SwitchToggle
                              id="1"
                              label="To retrieve daily leads data from VinSolutions"
                              defaultChecked={
                                settingsHash?.crm_integrations
                                  ?.to_retrieve_daily_leads
                              }
                              onChange={(check: boolean) => {
                                handleInputChange(
                                  "crm_integrations",
                                  "to_retrieve_daily_leads",
                                  check
                                );
                              }}
                            />
                            <SwitchToggle
                              id="1"
                              label="Call Tracking - To send call logs to VinSolutions"
                              defaultChecked={
                                settingsHash?.crm_integrations?.to_call_tracking
                              }
                              onChange={(check: boolean) => {
                                handleInputChange(
                                  "crm_integrations",
                                  "to_call_tracking",
                                  check
                                );
                              }}
                            />
                          </HStack>
                          {data?.data?.settings_hash?.crm_integrations
                            ?.user_id &&
                            data?.data?.settings_hash?.crm_integrations
                              ?.subscription_id && (
                              <VinSolution
                                id={id}
                                dealerId={
                                  settingsHash?.crm_integrations
                                    ?.subscription_id
                                }
                              />
                            )}
                        </>
                      )}
                    </VStack>
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {selectedCRMTabIndex === 1 && (
                  <ApiLogs
                    id={id}
                    type={settingsHash?.crm_integrations?.crm_provider}
                    companyid={settingsHash?.crm_integrations?.subscription_id}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          gap="1.5rem"
        >
          <Text fontWeight="700" textStyle="h4">
            DMS Integrations
          </Text>
          <Tabs
            variant="primary"
            w="100%"
            onChange={handleDMSTabsChange}
            defaultIndex={0}
            index={selectedDMSTabIndex}
          >
            <TabList>
              <Tab>Integration</Tab>
              <Tab>API Logs</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {selectedDMSTabIndex === 0 && (
                  <>
                    <VStack w="100%" gap="1.25rem" alignItems="flex-start">
                      <HStack paddingTop={"1.25rem"}>
                        <SwitchToggle
                          label="Active"
                          defaultChecked={
                            settingsHash?.dms_integrations?.status
                          }
                          onChange={(val) =>
                            handleInputChange("dms_integrations", "status", val)
                          }
                        />
                      </HStack>
                      <HStack w="100%" gap="1.25rem" alignItems="flex-start">
                        <Select
                          label="Select DMS Provider"
                          options={
                            dms?.data?.map((crm: any) => ({
                              label: crm,
                              value: crm,
                            })) ?? []
                          }
                          variant="default"
                          w="50%"
                          value={settingsHash?.dms_integrations?.dms_provider}
                          onChange={(value) => {
                            handleInputChange(
                              "dms_integrations",
                              "dms_provider",
                              value?.value
                            );
                          }}
                        />
                        <Input
                          maxW="50%"
                          label="Subscription ID"
                          defaultValue="Auto Expo"
                          value={
                            settingsHash?.dms_integrations?.subscription_id
                          }
                          onChange={(e) => {
                            handleInputChange(
                              "dms_integrations",
                              "subscription_id",
                              e?.target?.value
                            );
                          }}
                        />
                      </HStack>
                    </VStack>
                    <VStack w="100%" gap="1.25rem" alignItems="flex-start">
                      <HStack
                        w="100%"
                        gap="1.25rem"
                        alignItems="flex-start"
                        paddingTop={"1.25rem"}
                      >
                        {" "}
                        <SwitchToggle
                          id="1"
                          label="Sales"
                          defaultChecked={
                            settingsHash?.dms_integrations?.include_sales
                          }
                          onChange={(check: boolean) => {
                            handleInputChange(
                              "dms_integrations",
                              "include_sales",
                              check
                            );
                          }}
                        />
                        <SwitchToggle
                          id="1"
                          label="Services"
                          defaultChecked={
                            settingsHash?.dms_integrations?.include_service
                          }
                          onChange={(check: boolean) => {
                            handleInputChange(
                              "dms_integrations",
                              "include_service",
                              check
                            );
                          }}
                        />
                        <SwitchToggle
                          id="1"
                          label="R.O. Tradeup"
                          defaultChecked={
                            settingsHash?.dms_integrations?.include_repair_order
                          }
                          onChange={(check: boolean) => {
                            handleInputChange(
                              "dms_integrations",
                              "include_repair_order",
                              check
                            );
                          }}
                        />
                      </HStack>
                    </VStack>
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {selectedDMSTabIndex === 1 && (
                  <ApiLogs id={id} type={"cdk_sales"} companyid={id} />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>

        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          gap="1.5rem"
        >
            <HStack
               justifyContent="space-between"
               display="flex"
               alignItems="center"
               w="100%"

        >
          <Text fontWeight="700" textStyle="h4">
            Sarah.ai Integration
          </Text>
          <Select
            options={formoptions}
            label="Select Form"
            value={form}
            onChange={formHandler}
            w="20%"
          />
          </HStack>
          <VStack w="100%" gap="1.25rem" alignItems="flex-start">
            <HStack>
              <SwitchToggle
                label="Sarah.ai Integration"
                defaultChecked={settingsHash?.[form]?.chat_gpt_integration}
                onChange={(val) =>
                  handleInputChange([form], "chat_gpt_integration", val)
                }
              />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <CustomRadio
                label="Sarah.ai Hours of Operation"
                options={hoursOption}
                value={settingsHash?.[form]?.operating_hours}
                setValue={(val) => {
                  handleInputChange([form], "operating_hours", val);
                  if (val === "specific_hours") {
                    setSettingsHash((prevState: any) => ({
                      ...prevState,
                      [form]: {
                        ...prevState?.[form],
                        hours:
                          prevState?.[form]?.hours?.length > 0
                            ? prevState?.[form]?.hours
                            : Array.from({ length: 7 }, (_, i) => ({
                                label: [
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                  "Sunday",
                                ][i],
                                checked: false,
                                startTime: "",
                                endTime: "",
                              })),
                      },
                    }));
                  }
                }}
              />
            </HStack>
            {settingsHash?.[form]?.operating_hours === "specific_hours" &&
              (settingsHash?.[form]?.hours || daysStructure)?.map(
                (item: any, index: any) => (
                  <Stack direction="row" alignItems="center">
                    <VStack mt="18px" minW="104px" alignItems="flex-start">
                      <Checkbox
                        size="lg"
                        variant="whiteCheck"
                        isChecked={item?.checked}
                        onChange={(e) => {
                          handleHoursChange(index, "checked", e.target.checked);
                        }}
                      >
                        {item?.label}
                      </Checkbox>
                    </VStack>

                    <Input
                      label="Start Time"
                      hasIcon={true}
                      isIconRight={true}
                      icon={<TimeIcon />}
                      type="time"
                      maxW="lg"
                      value={item?.startTime}
                      onChange={(e) => {
                        handleHoursChange(index, "startTime", e?.target?.value);
                      }}
                    />

                    <Input
                      label="End Time"
                      hasIcon={true}
                      isIconRight={true}
                      icon={<TimeIcon />}
                      type="time"
                      maxW="lg"
                      value={item?.endTime}
                      onChange={(e) => {
                        handleHoursChange(index, "endTime", e?.target?.value);
                      }}
                    />
                  </Stack>
                )
              )}

            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Input
                maxW="100%"
                label="Website Chat"
                isTextarea={true}
                defaultValue="Generate an SMS to introduce yourself as a non-aggressive car salesman namely Sarah at [company_name] to customers, involving saying how this is the perfect car for fun and work. Your goal is to set up an appointment for today, schedule a phone call, or get a credit application filled"
                value={settingsHash?.[form]?.website_chat}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "website_chat",
                    e.target.value
                  );
                }}
              />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="Offering"
                  isTextarea={true}
                  value={settingsHash?.[form]?.offering}
                  onChange={(e) => {
                    handleInputChange(formRef.current, "offering", e?.target?.value);
                  }}
                />
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="Not Offering"
                  isTextarea={true}
                  value={settingsHash?.[form]?.not_offering}
                  onChange={(e) => {
                    handleInputChange(
                      formRef.current,
                      "not_offering",
                      e?.target?.value
                    );
                  }}
                />
              </VStack>
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Input
                maxW="100%"
                label="Hours of Operation"
                value={settingsHash?.[form]?.hours_of_operation}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "hours_of_operation",
                    e?.target?.value
                  );
                }}
              />
              <Input
                maxW="100%"
                label="Wait time for Sarah.ai turnover"
                value={settingsHash?.[form]?.wait_time}
                onChange={(e) => {
                  handleInputChange(formRef.current, "wait_time", e);
                }}
                type="number"
                isNumeric
              />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Input
                maxW="100%"
                label="Response if the contact says no"
                value={settingsHash?.[form]?.response_if_contact_say_no}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "response_if_contact_say_no",
                    e?.target?.value
                  );
                }}
              />

              <Input
                maxW="100%"
                label="Response if the contact wants to make a call"
                value={
                  settingsHash?.[form]?.response_if_contact_wants_to_make_call
                }
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "response_if_contact_wants_to_make_call",
                    e?.target?.value
                  );
                }}
              />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Input
                maxW="100%"
                label="Sequence id to enrol if 5 attempts no response"
                value={
                  settingsHash?.[form]
                    ?.sequence_id_to_enroll_5_attempts_no_response
                }
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "sequence_id_to_enroll_5_attempts_no_response",
                    e?.target?.value
                  );
                }}
              />

              <Input
                maxW="100%"
                label="1st followup message"
                value={settingsHash?.[form]?.first_follow_up_message}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "first_follow_up_message",
                    e?.target?.value
                  );
                }}
              />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Input
                maxW="100%"
                label="2nd followup message"
                value={settingsHash?.[form]?.second_follow_up_message}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "second_follow_up_message",
                    e?.target?.value
                  );
                }}
              />

              <Input
                maxW="100%"
                label="3rd followup message"
                value={settingsHash?.[form]?.third_follow_up_message}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "third_follow_up_message",
                    e?.target?.value
                  );
                }}
              />
            </HStack>
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Input
                maxW="100%"
                label="4th followup message"
                value={settingsHash?.[form]?.fourth_follow_up_message}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "fourth_follow_up_message",
                    e?.target?.value
                  );
                }}
              />

              <Input
                maxW="100%"
                label="5th followup message"
                value={settingsHash?.[form]?.fifth_follow_up_message}
                onChange={(e) => {
                  handleInputChange(
                    formRef.current,
                    "fifth_follow_up_message",
                    e?.target?.value
                  );
                }}
              />
            </HStack>
          </VStack>
        </VStack>

        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          gap="1.5rem"
        >
          <Text fontWeight="700" textStyle="h4">
            Inventory Feed Provider
          </Text>
          <VStack w="100%" gap="1.25rem" alignItems="flex-start">
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Select
                label="Inventory Provider"
                options={inventory?.data?.inventory_feed_providers?.map(
                  (user: any) => ({
                    value: user.id.toString(),
                    label: user.provider_name,
                  })
                )}
                variant="default"
                w="100%"
                isMulti={false}
                onChange={(value) => {
                  handleInventoryProviderChange(value);
                  handleInputChange(
                    "inventory_provider_params",
                    "id",
                    value.value
                  );
                  handleInputChange(
                    "inventory_provider_params",
                    "datatype",
                    inventory?.data?.inventory_feed_providers?.find(
                      (val: any) => val?.id == value.value
                    )?.datatype
                  );
                }}
                value={inventorymsg?.inventory_feed_provider_id}
              />
              <Select
                w="100%"
                onChange={(value) => {
                  handleInputChange(
                    "inventory_provider_params",
                    "datatype",
                    value.value
                  );
                }}
                placeholder="Select Data Type"
                options={options}
                variant="default"
                value={settingsHash?.inventory_provider_params?.datatype}
                label="Data Type"
                isDisabled={true}
              />
            </HStack>
            <HStack w="50%" gap="1.25rem" alignItems="flex-start">
              {settingsHash?.inventory_provider_params?.datatype ===
                "multiple_dealerships_directory" && (
                <Input
                  label="File Name"
                  onChange={(e: any) => {
                    handleInputChange(
                      "inventory_provider_params",
                      "mapping_params",
                      {
                        file_name: e.target.value,
                        dealership_identifier: "",
                      }
                    );
                  }}
                  value={
                    settingsHash?.inventory_provider_params?.mapping_params
                      ?.file_name
                  }
                  isRequired={true}
                  error={
                    !settingsHash?.inventory_provider_params?.mapping_params
                      ?.file_name
                      ? "Required"
                      : ""
                  }
                />
              )}
              {settingsHash?.inventory_provider_params?.datatype ===
                "multiple_dealerships_file" && (
                <Input
                  label="Dealership Identifier"
                  onChange={(e: any) => {
                    handleInputChange(
                      "inventory_provider_params",
                      "mapping_params",
                      {
                        file_name: "",
                        dealership_identifier: e.target.value,
                      }
                    );
                  }}
                  value={
                    settingsHash?.inventory_provider_params?.mapping_params
                      ?.dealership_identifier
                  }
                  isRequired
                  error={
                    !settingsHash?.inventory_provider_params?.mapping_params
                      ?.dealership_identifier
                      ? "Required"
                      : ""
                  }
                />
              )}
            </HStack>
            <VStack w="100%" alignItems="flex-start" gap="0">
              <Input
                label={`CC'd emails`}
                w="50%"
                border="1px solid var(--grey-300)"
                onKeyDown={handleCcChange}
                placeholder={"Type a valid email and press enter to save"}
              />
              <Box
                display="flex"
                gap="0.31rem"
                alignItems="flex-end"
                flexWrap="wrap"
              >
                {renderPills()}
              </Box>
            </VStack>
            <Button
              variant="outline"
              // leftIcon={
              //   <AddIcon
              //     style={{
              //       color: "var(--grey-800)",
              //     }}
              //   />
              // }
              isDisabled={
                // inventorymsg?.cc?.length === 0 ||
                inventorymsg?.inventory_feed_provider_id === ""
              }
              onClick={sendMessage}
            >
              Send Email
            </Button>
          </VStack>
          <VStack w="50%" alignItems="flex-start">
            <Text fontWeight="700">FTP Credentials</Text>
            <HStack w="100%" alignItems="flex-start">
              <VStack gap="0.25rem" alignItems="flex-start" w="50%">
                <Text textStyle="h6" fontWeight="599">
                  Host
                </Text>
                <Text textStyle="h5" color="var(--grey-700)">
                  {ftp?.data?.settings_hash?.host ?? "-"}
                </Text>
              </VStack>
              <VStack gap="0.25rem" alignItems="flex-start" w="50%">
                <Text textStyle="h6" fontWeight="599">
                  Username
                </Text>
                <Text textStyle="h5" color="var(--grey-700)">
                  {ftp?.data?.settings_hash?.username ?? "-"}
                </Text>
              </VStack>
            </HStack>
            <HStack w="100%" alignItems="flex-start">
              <VStack gap="0.25rem" alignItems="flex-start" w="50%">
                <Text textStyle="h6" fontWeight="599">
                  Password
                </Text>
                <Text textStyle="h5" color="var(--grey-700)">
                  {ftp?.data?.settings_hash?.password ?? "-"}
                </Text>
              </VStack>
              {/* <VStack gap="0.25rem" alignItems="flex-start" w="50%">
                <Text textStyle="h6" fontWeight="599">
                  Port
                </Text>
                <Text textStyle="h5" color="var(--grey-700)">
                  {ftp?.data?.settings_hash?.port ?? "-"}
                </Text>
              </VStack> */}
            </HStack>
          </VStack>
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          gap="1.5rem"
          ref={myDivRef}
        >
          <Text fontWeight="700" textStyle="h4">
            Social Media Integration
          </Text>
          <Tabs
            variant="primary"
            w="100%"
            onChange={handleTabsChange}
            defaultIndex={0}
            index={selectedTabIndex}
          >
            <TabList>
              <Tab>Facebook</Tab>
              <Tab>Tiktok</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {selectedTabIndex === 0 && (
                  <FacebookIntegration userId={userid} id={id} />
                )}
              </TabPanel>
              <TabPanel>
                {selectedTabIndex === 1 && (
                  <TiktokIntegration
                    userId={userid}
                    id={id}
                    enabled={
                      data?.data?.settings_hash?.advertisers
                        ?.tiktok_advertisers_id
                    }
                    value={settingsHash?.advertisers?.tiktok_advertisers_id}
                    onChange={(e: any) => {
                      handleInputChange(
                        "advertisers",
                        "tiktok_advertisers_id",
                        e?.target?.value
                      );
                    }}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text fontWeight="700" textStyle="h4">
            Advertisers
          </Text>
          <VStack w="100%" gap="1.25rem" alignItems="flex-start">
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <VStack alignItems="flex-start" w="100%">
                <Input
                  maxW="100%"
                  label="Facebook Page ID"
                  defaultValue="5381347978"
                  value={settingsHash?.advertisers?.facebook_page_id}
                  onChange={(e) => {
                    handleInputChange(
                      "advertisers",
                      "facebook_page_id",
                      e?.target?.value
                    );
                  }}
                />
                <HStack
                  onClick={() =>
                    window.location.replace(getMessengerCheckout(id))
                  }
                  pointerEvents={
                    data?.data?.settings_hash?.advertisers?.facebook_page_id !==
                    ""
                      ? "auto"
                      : "none"
                  } // Enable/disable pointer events based on props.allowed
                >
                  <MessengerLogo />
                  <Text textStyle="h6">Authorize Facebook messenger</Text>
                  <Link to="#">
                    <RouteIcon />
                  </Link>
                </HStack>
              </VStack>
            </HStack>
          </VStack>
        </VStack>
        <FeedUrls />
        <HStack
          position="fixed"
          bottom="1.5rem"
          w="calc(100% - 596px)"
          right="1.5rem"
          background="white"
          justifyContent="flex-end"
          padding="1rem"
          borderTop="1px solid var(--grey-300)"
        >
          <Button
            width="8rem"
            onClick={handleSubmit}
            isDisabled={
              settingsHash?.inventory_provider_params?.id &&
              settingsHash?.inventory_provider_params?.datatype !==
                "single_file" &&
              !settingsHash?.inventory_provider_params?.mapping_params
                ?.file_name &&
              !settingsHash?.inventory_provider_params?.mapping_params
                ?.dealership_identifier
            }
          >
            Save
          </Button>
        </HStack>
        {(isLoading || mutation.isLoading || msgMutation.isLoading) && (
          <Loader />
        )}
      </VStack>
    </>
  );
};

export default Integration;
