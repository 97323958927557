import {
  Box,
  Button,
  DrawerFooter,
  useDisclosure,
  FormLabel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import { Drawer, Input, Select } from "../../../components/common";
import YearInput from "../../../components/common/YearInput";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import DatePicker from "../../../components/common/DateRangePicker";
import { ContactsDrawer } from "./ContactsDrawer";
import {
  getLeadWarById,
  fetchMake,
  fetchModel,
} from "../../../utils/api/leadwar.api";
import {
  loadLeadSource,
  loadDispositionSettings,
} from "../bdc-campaign/CreateCampaign/utils";
import EmailContent from "../dms-equity/EmailContent";
import SmsContent from "../dms-equity/SmsContent";
const schema = (isSms: boolean) => {
  return yup.object().shape({
    name: yup.string().nullable().required("Enter name"),
    content: isSms
      ? yup.string().nullable().required("Enter Message").max(1599)
      : yup
          .string()
          .nullable()
          .required("Enter Message")
          .test(
            "max-text-length",
            "Content cannot exceed 2499 characters in plain text",
            function (value) {
              if (!value) return true; // if no content, validation passes

              // Create a temporary element to convert HTML to plain text
              const tempElement = document.createElement("div");
              tempElement.innerHTML = value;
              const textContent =
                tempElement.innerText || tempElement.textContent || "";

              // Check if the plain text length exceeds 2499 characters
              return textContent.length <= 2499;
            }
          ),
    year: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Year must be an integer"),
    // make: yup.string().required("Select make"),
    model: yup.string().nullable(),
    startRange: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0")
      .when("endRange", (endRange, schema) =>
        schema.max(endRange - 1, "Start range must be smaller than end range")
      ),
    endRange: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0"),
    startRangeOfPayment: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0")
      .when("endRangeOfPayment", (endRange, schema) =>
        schema.max(
          endRange - 1,
          "Start range of payments must be smaller than end range"
        )
      ),
    endRangeOfPayment: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Start range must be an integer")
      .min(0, "Start range must be greater than 0"),
  });
};

const CreateDigitalLead = (props: any) => {
  const { onClose, refetch, id = null, isSms } = props;
  const dId = useUserInfo("dealership")?.id;
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);
  const [formData, setFormData] = useState(null);

  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();

  const { control, handleSubmit, watch, setValue, getValues, trigger } =
    useForm<any>({
      resolver: yupResolver(schema(isSms)),
    });
  const values = watch();
  const make = useWatch({
    control,
    name: "make",
  });
  const { data } = useQuery({
    queryKey: ["fetchmakeapi", dId],
    queryFn: fetchMake,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { data: model } = useQuery({
    queryKey: ["fetchmodelapi", dId, make],
    queryFn: fetchModel,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!make,
  });
  useEffect(() => {
    setValue("date", {
      startDate: "",
      endDate: "",
      key: "selection",
      selected: false,
    });
  }, []);
  const { isLoading } = useQuery({
    queryKey: ["fetchleadwarbyid", dId, id],
    queryFn: getLeadWarById,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess(data) {
      const formData = data.data;
      setValue("name", formData.name);
      setValue("date", formData.date);
      setValue("year", formData?.filters?.year);
      setValue("make", formData?.filters?.make);
      setValue("model", formData?.filters?.model);
      setValue("exterior_color", formData?.filters?.exterior_color);
      setValue("date", {
        startDate: formData?.filters?.start_date
          ? new Date(formData?.filters?.start_date)
          : null,
        endDate: formData?.filters?.end_date
          ? new Date(formData?.filters?.end_date)
          : null,
        key: "selection",
        selected: true,
      });
      setValue("content", formData?.content);
      setValue("subject", formData?.subject);
      setValue(
        "disposition_status",
        formData?.filters?.dispositions?.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        }))
      );
      setValue(
        "lead_sources",
        formData?.filters?.lead_sources?.map((item: any) => ({
          value: item?.id,
          label: item?.name,
        }))
      );
      formData?.line?.id &&
        setValue("line", [
          {
            value: formData?.line?.id,
            label: formData?.line?.phone_number,
          },
        ]);
      setAttachmentUrls(
        formData?.media_urls?.map((item: any) => ({
          url: item,
          filename: item,
        }))
      );
    },
  });
  const onSubmit = (data: any) => {
    const { date, estimatedMMRValue, purchaseMethod, ...rest } = data;
    const transformedData: any = {};
    if (
      date &&
      (date?.selected || id) &&
      date?.startDate &&
      date?.endDate &&
      String(date?.startDate) !== String(date?.endDate)
    ) {
      transformedData.start_date = date.startDate;
      transformedData.end_date = date.endDate;
    }
    setFormData({ ...transformedData, ...rest });
  };
  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      title={
        id
          ? "Edit Digital Lead War Campaign"
          : "Create Digital Lead War Campaign"
      }
      isLoading={id ? isLoading : false}
    >
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Box padding="1.25rem">
          <Box mb="1rem">
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  placeholder="Enter Name"
                  label="Digital Lead War Campaign Name"
                  type="text"
                  error={error?.message || ""}
                  isRequired
                  maxLength={200}
                />
              )}
            />{" "}
          </Box>
          <Box mb="1rem" width="100%">
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  shouldShowClear
                  isRange
                  label={"Date range of Lead Received"}
                  w="100%"
                />
              )}
            />
          </Box>

          <Box mb="1rem">
            <YearInput control={control} />
          </Box>

          <Box mb="1rem">
            <Controller
              name="make"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  placeholder="Select Make"
                  label="Make"
                  error={error?.message}
                  options={data?.data?.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(val: any) => {
                    setValue("make", val?.value, { shouldValidate: true });
                    setValue("model", undefined, { shouldValidate: true });
                    trigger("make");
                  }}
                  w="100%"
                  variant="default"
                />
              )}
            />
          </Box>

          <Box mb="1rem"></Box>

          <Box mb="1rem">
            <Controller
              name="model"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  placeholder="Select Model"
                  label="Model"
                  error={error?.message || ""}
                  options={model?.data?.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(val) => setValue("model", val?.value)}
                  w="100%"
                />
              )}
            />{" "}
          </Box>
          <Box mb="1rem">
            <Controller
              name="exterior_color"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  placeholder="Enter color"
                  label="Exterior Color"
                  error={error?.message || ""}
                  maxLength={200}
                />
              )}
            />
          </Box>

          <Box mb="1rem">
            <FormLabel
              mb="0.31rem"
              color="var(--grey-900)"
              fontSize="0.875rem"
              fontWeight="600"
              lineHeight="1rem"
            >
              Lead Sources
            </FormLabel>
            <Controller
              name="lead_sources"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <AsyncSelect
                  {...field}
                  loadOptions={(searchStr) => loadLeadSource(searchStr, dId)}
                  defaultOptions
                  isMulti
                  placeholder="Select Lead Source"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              )}
            />
          </Box>

          <Box mb="1rem">
            <FormLabel
              mb="0.31rem"
              color="var(--grey-900)"
              fontSize="0.875rem"
              fontWeight="600"
              lineHeight="1rem"
            >
              Disposition Status
            </FormLabel>
            <Controller
              name="disposition_status"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <AsyncSelect
                  loadOptions={(searchStr) =>
                    loadDispositionSettings(searchStr, dId)
                  }
                  defaultOptions
                  {...field}
                  isMulti={true}
                  placeholder="Select Disposition Status"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                  }}
                />
              )}
            />
          </Box>

          {isSms ? (
            <Box className="attachmentViewerWrapper">
              <SmsContent
                control={control}
                setValue={setValue}
                getValues={getValues}
                attachmentUrls={attachmentUrls}
                setAttachmentUrls={setAttachmentUrls}
              />
            </Box>
          ) : (
            <Box className="dmsEquityMiningEditor">
              <EmailContent
                control={control}
                setValue={setValue}
                getValues={getValues}
                attachmentUrls={attachmentUrls}
                setAttachmentUrls={setAttachmentUrls}
              />
            </Box>
          )}
        </Box>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
          background="white"
        >
          <Button
            w="100%"
            size="xl"
            type="submit"
            onClick={onContactsOpen}
            isDisabled={
              !(values?.name&& values?.content?.trim())
            }
          >
            Next
          </Button>
        </DrawerFooter>
      </Box>
      {isContactsOpen && formData && (
        <ContactsDrawer
          onClose={onClose}
          refetch={refetch}
          id={id}
          isContactsOpen={isContactsOpen}
          onContactsClose={onContactsClose}
          formData={formData}
          category="sales"
          attachmentUrls={attachmentUrls}
          isSms={isSms}
        ></ContactsDrawer>
      )}
    </Drawer>
  );
};

export default CreateDigitalLead;
