import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionCableContext } from "../../../App";
import SearchAndFilterDrawer from "../../../components/Drawers/SearchAndFilterDrawer";
import Calendar from "../../../components/LeadCenter/Calendar/Calendar";
import LeadResponse from "../../../components/LeadCenter/LeadResponse/LeadResponse";
import Priority from "../../../components/LeadCenter/Priority/Priority";
import CreateNewLead from "../../../components/Leads/CreateNewLead";
import CustomInput from "../../../components/common/Input";
import Loader from "../../../components/common/Spinner";
import SwitchToggle from "../../../components/common/Switch";
import {
  DarkFilterIcon,
  ImportIcon,
  SearchIcon,
} from "../../../components/icons/svg";
import { getDashboardStats } from "../../../utils/api/lead.api";
import {
  fetchUserSettingsDP,
  updateUserSettingsDP,
} from "../../../utils/api/user-settings-dp.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import ImportContacts from "./ImportContacts";
// import React from "react";
// import { Center, Heading, Text, VStack, Image } from "@chakra-ui/react";
// import UnderConstruction from "../../../components/UnderConstruction/UnderConstruction";
// export default function LeadsPage() {
//   return (
//     <UnderConstruction/>
//   );
// };

export default function LeadsPage() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoaded, setIsLoaded] = useState(false);
  const [newLeadOpen, setNewLeadOpen] = useState<boolean>(false);
  const [searchAndFilterOpen, setSearchAndFilterOpen] =
    useState<boolean>(false);
  const [dashboardStats, setDashboardStats] = useState(null);
  const searchRef = useRef("");
  const navigate = useNavigate();
  const user = useUserInfo("user");
  const dealership = user?.dealership;
  const toast = useToast();

  //..........for focus mode.......
  const {
    data,
    isFetching: isLoadingFocusMode,
    refetch,
  } = useQuery({
    queryKey: ["focusMode", user],
    queryFn: () =>
      fetchUserSettingsDP(
        user?.dealership?.id,
        user?.id,
        "visibility_settings"
      ),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const {
    isLoading,
    refetch: refetchDashBoard,
    isFetching,
  } = useQuery({
    queryKey: ["dashboardStats", data],
    queryFn: () => getDashboardStats({ dealershipId: dealership?.id }),
    retry: false,
    onSuccess: (data) => {
      if (!isLoaded) setIsLoaded(true);
      setDashboardStats(data.data);
    },
  });

  const { cable } = useContext(ActionCableContext);

  const { id: dealerId } = dealership || {};
  const token = useUserInfo("token");

  useEffect(() => {
    try {
      if (!cable) return;
      cable.subscriptions.create(
        {
          channel: "DashboardStatsChannel",
          dealership_id: dealerId,
          user_id: user?.id,
          token: token,
        },
        {
          connected: () => {
            console.log("Connected to DashboardStats channel");
          },
          disconnected: () => {
            console.log("Disconnected from DashboardStats channel");
          },
          received: async (data: any) => {
            console.log("received data");
            refetchDashBoard();
          },
        }
      );
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cable, token, dealerId]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      searchRef.current = e.currentTarget.value;
      navigate(`/leads/lead-details?search=${searchRef.current}`);
    }
  };

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) =>
      await updateUserSettingsDP(payload, user?.dealership?.id, user?.id),
    onSuccess: () => {
      refetch();
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  return (
    <Box>
      {isLoaded ? <></> : (isLoading || isFetching) && <Loader />}
      {(isLoadingFocusMode || mutation.isLoading) && <Loader />}
      <HStack
        padding="1.25rem 1.5rem"
        background="white"
        justifyContent="space-between"
      >
        <HStack gap="0.75rem" w="100%">
          <CustomInput
            hasIcon={true}
            icon={<SearchIcon />}
            placeholder="Search Contact.."
            type="text"
            maxW="17rem"
            value={searchRef.current}
            onChange={({ target: { value } }) => (searchRef.current = value)}
            onKeyDown={handleKeyDown as any}
          />
          {/* <DatePicker /> */}
          <Button
            variant="outline"
            leftIcon={<DarkFilterIcon />}
            padding="0"
            border="1px solid var(--grey-300)"
            height="2.75rem"
            onClick={() => setSearchAndFilterOpen(true)}
          >
            <HStack>
              <Text padding="0.62rem 0.5rem">Filter & Sort</Text>
              {/* <Box
                as="span"
                borderLeft="1px solid var(--grey-300)"
                padding="0.8rem 0.6rem"
              >
                <ChevronDown color="var(--grey-900)" />
              </Box> */}
            </HStack>
          </Button>
          <HStack
            borderRadius="0.5rem"
            background="var(--grey-100)"
            padding="0.62rem"
            height="2.75rem"
          >
            <SwitchToggle
              label={`Focus Mode : ${
                data?.settings_hash?.focus_mode ? "On" : "Off"
              }`}
              id="#1"
              defaultChecked={data?.settings_hash?.focus_mode}
              onChange={() => {
                const requestData = {
                  key: "visibility_settings",
                  settings_hash: {
                    focus_mode: !data?.settings_hash?.focus_mode,
                  },
                };

                mutation.mutate(requestData);
              }}
            />
          </HStack>
        </HStack>
        <HStack gap="0.75rem">
          <Button variant="outlineBlue" onClick={() => setNewLeadOpen(true)}>
            Create Lead
          </Button>
          <Button leftIcon={<ImportIcon color="white" />} onClick={onOpen}>
            {" "}
            Import Contact
          </Button>
        </HStack>
      </HStack>
      <HStack
        padding="1.5rem 0 0 1.5rem"
        gap="1.5rem"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <VStack
          flex="1"
          alignItems="flex-start"
          height="calc(100vh - 178px)"
          overflow="auto"
          gap="1.5rem"
        >
          <LeadResponse dashboardStats={dashboardStats} />
          <HStack w="100%" gap="1.5rem" paddingBottom="1.5rem">
            {["high", "normal", "medium"].map((item, index) => (
              <Priority
                priority={item}
                key={index}
                number={index + 1}
                dashboardStats={dashboardStats}
              />
            ))}
          </HStack>
        </VStack>
        <Calendar />
      </HStack>

      {isOpen && (
        <ImportContacts
          onClose={() => {
            onClose();
            refetchDashBoard();
          }}
        ></ImportContacts>
      )}

      {/* Create Lead Drawer */}

      {newLeadOpen && (
        <CreateNewLead
          isOpen={newLeadOpen}
          onClose={() => setNewLeadOpen(false)}
        />
      )}

      {searchAndFilterOpen && (
        <SearchAndFilterDrawer
          isOpen={searchAndFilterOpen}
          onClose={() => setSearchAndFilterOpen(false)}
          screenName={"LeadDetailsPage"}
        />
      )}
    </Box>
  );
}
