import { Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../../components/common/Spinner";
import {
  emailValidation,
  phoneValidation,
  requiredString,
  textValidation,
} from "../../../utils";
import { fetchContact } from "../../../utils/api/guest-mode";
import AddEmpInfo from "./AddEmpInfo";
import PersonalInfo from "./PersonalInfo";

const ApplyForCredit = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { id, dId } = useParams();

  const schema = (contactId: string, activeStep: number) => {
    return yup.object().shape({
      ...(activeStep === 1
        ? {
            address_attributes: yup.object().shape({
              street_address: requiredString("Street Address"),
              unit: requiredString("Unit #"),
              city: requiredString("City"),
              state: requiredString("State"),
              zip: requiredString("Zip"),
              country: requiredString("Country"),
              residence_status: requiredString("Residency Status"),
              rental_mortgage_amount: requiredString("Rent/Mortgage Amount"),
              years: requiredString("Years"),
              months: requiredString("Months"),
            }),
            license: requiredString("License"),
            ssn: requiredString("SSN"),
            lic_state: requiredString("LIC State"),
            marital_status: requiredString("Marital Status"),
            birthday: requiredString("Birthday"),
            employments_attributes: yup.array().of(
              yup.object().shape({
                company_name: requiredString("Company name"),
                monthly_income: requiredString("Monthly income"),
                years: requiredString("Years"),
                months: requiredString("Months"),
                occupation: requiredString("Occupation"),
                title: requiredString("Title"),
                phone: phoneValidation(),
              })
            ),
          }
        : {
            first_name:
              contactId === "[contact_id]"
                ? textValidation("First Name")
                : textValidation("First name").required(
                    "First Name is required"
                  ),
            middle_name: textValidation("Middle name"),
            last_name: textValidation("Last name"),
            phone_number: phoneValidation(),
            prefix:
              contactId === "[contact_id]"
                ? yup.string().nullable()
                : yup.string().nullable().required("Prefix is required"),
            company_name:
              contactId === "[contact_id]"
                ? yup.string().nullable()
                : requiredString("Company name"),
            home_number: phoneValidation(),
            work_number: phoneValidation(),
            email: emailValidation(),
          }),
    });
  };
  const schemaObject = schema(id || "", activeStep);

  const { control, handleSubmit, setValue } = useForm<any>({
    resolver: yupResolver(schemaObject),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employments_attributes",
  });

  const { data, isFetching } = useQuery({
    queryKey: ["fetchContact", { contact_uuid: id }, dId],
    queryFn: fetchContact,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
    onSuccess(data: any) {
      if (data?.data?.contacts) {
        const { address_attributes, employments_attributes, ...rest } =
          data?.data?.contacts;

        // making arrays of key value pairs of fields without nested objects
        Object.entries(rest).forEach(([key, value]: any) => {
          setValue(key, value);
        });

        // nested object
        if (address_attributes) {
          Object.entries(address_attributes).forEach(([key, value]: any) => {
            setValue(`address_attributes.${key}`, value);
          });
        }

        // Update nested employment fields
        if (employments_attributes && employments_attributes.length > 0) {
          employments_attributes.forEach((employment: any, index: any) => {
            append(employment);
          });
        } else {
          setValue("employments_attributes", [{}]);
        }
      }
    },
  });

  return (
    <VStack bg="#fff" w="100%" justifyContent="center">
      <VStack
        alignItems="flex-start"
        w={{ base: "100%", md: "100%" }}
        padding={{ base: "0.5rem", md: "1rem" }}
        h="100%"
      >
        {data?.data?.credit_app_link ? (
          <VStack w="100%" h="100%" className="iframeContainer">
            <iframe
              id="applyForCreditIframeId"
              className="applyForCreditIframe"
              src={data?.data?.credit_app_link}
              title="Apply for Credit Link"
              scrolling="no"
              style={{
                border: "none",
                marginTop: "68px",
                // width: "99%",
                paddingRight: "34px",
                paddingLeft: "34px",
                paddingTop: "24px",
                background: "#fff",
              }}
            />
          </VStack>
        ) : (
          <VStack w="100%" alignItems="flex-start">
            <Text
              textStyle="h3"
              textAlign="left"
              pl="1rem"
              mt="1rem"
              fontWeight="700"
            >
              {activeStep === 0 ? "Personal Info" : "Employee Info"}
            </Text>

            <VStack
              alignItems="center"
              // w={{ base: "100%", md: "45%" }}
              w="100%"
              padding={{ base: "0", md: "1rem" }}
              h="100%"
            >
              {activeStep === 0 && (
                <PersonalInfo
                  setActiveStep={setActiveStep}
                  control={control}
                  handleSubmit={handleSubmit}
                  setValue={setValue}
                />
              )}
              {activeStep === 1 && (
                <AddEmpInfo
                  setActiveStep={setActiveStep}
                  control={control}
                  handleSubmit={handleSubmit}
                  setValue={setValue}
                  fields={fields}
                  append={append}
                  remove={remove}
                />
              )}
            </VStack>
          </VStack>
        )}
      </VStack>
      {isFetching && <Loader />}
    </VStack>
  );
};

export default ApplyForCredit;
