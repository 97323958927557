import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

// Import your tab content components here
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useTwilioDispatch,
  useTwilioState,
} from "../../../../utils/context/TwilioContext";
import Sevencredit from "./700Credit/700Credit";
import Assign from "./Assign/Assign";
import CoBuyer from "./CoBuyer/CoBuyer";
import Documents from "./Documents/Documents";
import DupCheck from "./DupCheck/DupCheck";
import History from "./History/History";
import { useMessageThread } from "./History/Messages/useMessageThread";
import Leads from "./Leads/Leads";
import Notes from "./Notes/Notes";
import Ping from "./Ping/Ping";
import ProcessSale from "./ProcessSale/ProcessSale";
import Reminder from "./Reminder/Reminder";
import SarahAI from "./SarahAI/SarahAI";
import StartMeeting from "./StartMeeting/StartMeeting";
import TradeIn from "./TradeIn/TradeIn";
import Vehicles from "./Vehicles/Vehicles";
import VideoChat from "./VideoChat/VideoChat";

const DetailTabs = ({ clickedRow, setSearchValue,updateContact }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeIndex = location?.state?.activeIndex;
  let [searchParams] = useSearchParams();
  const activeTab = searchParams.get("active_index");
  const [selectedIndex, setSelectedIndex] = useState(
    Number(activeIndex ?? activeTab) ?? 0
  );
  const [clickedRecord, setClickedRecord] = useState(null);
  const [historyActiveIndex, setHistoryActiveIndex] = useState(0);
  const [activeIndexHistory, setActiveIndexHistory] = useState(0);
  const indexSetter = (val: any, val2: any) => {
    setSelectedIndex(val);
    setActiveIndexHistory(val2);
  };

  const messageThreadProps = useMessageThread({
    clickedRecord,
    clickedRow,
    type: "message",
    activeIndex: historyActiveIndex,
  });

  const tabs = [
    {
      id: 0,
      title: "History",
      content: (
        <History
          clickedRow={clickedRow}
          activeIndexHistory={activeIndexHistory}
          messageThreadProps={messageThreadProps}
          setClickedRecord={setClickedRecord}
          clickedRecord={clickedRecord}
          activeIndex={historyActiveIndex}
          setActiveIndex={setHistoryActiveIndex}
        />
      ),
    },
    {
      id: 1,
      title: "Start Meeting",
      content: <StartMeeting clickedRow={clickedRow?.id} />,
    },
    { id: 2, title: "Vehicles", content: <Vehicles id={clickedRow?.id} /> },
    {
      id: 3,
      title: "Co-Buyer",
      content: (
        <CoBuyer clickedRow={clickedRow} setSearchValue={setSearchValue}updateContact={updateContact} />
      ),
    },
    { id: 4, title: "Trade In", content: <TradeIn clickedRow={clickedRow} /> },
    {
      id: 5,
      title: "Process Sale",
      content: (
        <ProcessSale clickedRow={clickedRow?.id} indexSetter={indexSetter} />
      ),
    },
    {
      id: 6,
      title: "Dup Check",
      content: <DupCheck clickedRow={clickedRow} />,
    },
    {
      id: 7,
      title: "Video Chat",
      content: <VideoChat clickedRow={clickedRow} />,
    },
    {
      id: 8,
      title: "Assign",
      content: <Assign clickedRow={clickedRow?.id} />,
    },
    { id: 9, title: "Notes", content: <Notes id={clickedRow?.id} /> },
    {
      id: 10,
      title: "Ping",
      content: (
        <Ping
          clickedRow={clickedRow}
          messageThreadProps={messageThreadProps}
          setClickedRecord={setClickedRecord}
          clickedRecord={clickedRecord}
          setActiveIndex={setHistoryActiveIndex}
        />
      ),
    },
    {
      id: 11,
      title: "Documents",
      content: <Documents clickedRow={clickedRow?.id} />,
    },
    {
      id: 12,
      title: "Sarah AI",
      content: <SarahAI clickedRow={clickedRow?.id} />,
    },
    {
      id: 13,
      title: "Reminder",
      content: <Reminder clickedRow={clickedRow?.id} />,
    },
    { id: 14, title: "Leads", content: <Leads clickedRow={clickedRow?.id} /> },
    {
      id: 17,
      title: "Credit Check",
      content: <Sevencredit clickedRow={clickedRow?.id} />,
    },
  ];

  const { openAppointment } = useTwilioState();
  const dispatch = useTwilioDispatch();

  useEffect(() => {
    if (openAppointment) {
      setSelectedIndex(0);
    }
  }, [openAppointment, selectedIndex]);

  useEffect(() => {
    setSelectedIndex(Number(activeTab) ?? 0);
  }, [activeTab, clickedRow]);

  return (
    <Box w="68%">
      {clickedRow?.id && (
        <Tabs
          variant="verticalContact"
          display="flex"
          w="100%"
          onChange={(index) => {
            dispatch({
              type: "OPEN_APPOINTMENT",
              payload: { openAppointment: false },
            });
            setSelectedIndex(index);
            navigate(location.pathname);
          }}
          index={selectedIndex}
          isLazy
          lazyBehavior="unmount"
        >
          <TabList
            w="12rem"
            mt="1rem"
            borderBottom="1px solid var(--grey-300)"
            h="calc(100vh - 130px)"
            overflowY="auto"
          >
            {tabs.map((item) => (
              <Tab
                key={item.id}
                color="var(--grey-800)"
                fontWeight="700"
                display="flex"
                alignItems="center"
                padding="0.8rem 1rem"
                w="10rem"
                fontSize="0.875rem"
                gap="0.5rem"
                _selected={{
                  background: "var(--primary-600)",
                  color: "white",
                }}
                sx={{
                  svg: {
                    width: "1.5rem",
                    height: "1.5rem",
                  },
                }}
              >
                {item.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels w="100%">
            {tabs.map((item, index) => (
              <TabPanel key={item.id}>
                {selectedIndex === index && item.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};

export default DetailTabs;
